
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

.intro {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    height: 100%;
    overflow-y: hidden;
    z-index: 10;
}

.titleBlock {
    margin-top: 15%;
}

.name {
    font-size: 20px;
    font-family: 'Press Start 2P', cursive;
    color: white;
    font-weight: bold;
}

.job {
    font-size: 48px;
    font-family: 'Press Start 2P', cursive;
    color: white;
    font-weight: bold;
}

.landingText {
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    padding: 20px;
}

.startButton {
    border: none;
    background: none;
    font-family: 'Press Start 2P', cursive;
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    color: white;
    cursor: pointer;
    animation: flickerAnimation 1.5s infinite;
    z-index: 100;
    cursor: pointer;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    padding: 60px;

}

@keyframes flickerAnimation {
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-o-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-moz-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }
  @-webkit-keyframes flickerAnimation{
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }


 @media(max-width: 1000px) {
     .job {
         font-size: 40px;
     }
 }

  @media(max-width: 480px) {
    .intro {
        justify-content: center;
    }

      .job {
          font-size: 32px;
      }
      .name {
          font-size: 16px;
      }
      .startButton {
          font-size: 12px;
      }
  }