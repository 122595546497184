@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');


.container {
  display: grid;
  grid-template-columns: 8% 84% 8%;
  grid-template-rows: 8% 84% 8%;
  /* width: 100%; */
  height: 100%;
}
.background {
  background: #add8e6;
  background: url('../../assets/images/Misc/purplestars.gif');
  background-size: 100% 100%;
  border-radius: 6%;
  z-index: 1;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 100%;

}

.text {
  font-size: 15px;
}

.profilePhoto {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
}

.homeContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.centerContainer {
  width: 75%;
  height: 75%;
  background: black;
  border: 2px solid hsl(0, 7%, 89%);
  color: #E6E2E2;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 8px;
  margin-right: 8px;
  padding: 4px;
  width: 40px;
  border: 1px solid #E6E2E2;
  background: none;
  cursor: pointer;
  color: #E6E2E2;
  font-family: 'Press Start 2P', cursive;
}

.home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.homeButtonsSection {
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.contactButtonsSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
}

.button {
  border: none;
  background: none;
  font-family: 'Press Start 2P', cursive;
  font-weight: normal;
  font-style: normal;
  pointer-events: auto;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.animate {
  animation: flickerAnimation 2s infinite;
}

.buttonText {
  color: #E6E2E2;
  font-family: 'Press Start 2P', cursive;
  font-size: 22px;
  text-transform: uppercase;
}

.anchorTag {
  text-decoration: none;
}

.contactTextColor {
  border-bottom: 2px solid #6a5acd;
  line-height: 28px;
  padding-bottom: 6px;
}

.icon {
  width: 90px;
  height: 66px;
}

.resumeIcon {
  border-radius: 3px;
}


.title {
  font-weight: bold;
  font-size: 36px;
  text-transform: uppercase;
}
.jobTitle {
  display: flex;
  justify-content: space-between;
  text-align: start;
}

.contentContainer {
  overflow: auto;
  width: 90%;
  height: 90%;
  font-family: 'Press Start 2P', cursive;
}

.contactContainer {
  width: 90%;
  height: 100%;
  font-family: 'Press Start 2P', cursive;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.about {
  text-align: center;
  color: #E6E2E2;
  font-family: 'Press Start 2P', cursive;
  line-height: 2;

}
.list {
  text-align: left;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

::-webkit-scrollbar-thumb {
  background: none;
}


@media (max-width: 770px) {

  .centerContainer {
    width: 85%;
    height: 85%;
  }

  .homeButtonsSection {
    flex-direction: column;
    height: 100%;
  }
  .buttonText {
    font-size: 16px;
  }

  .contactButtonsSection {
    flex-direction: column;
    height: 100%;
  }
  

}

@media (max-width: 480px) {

  .jobTitle {
    text-align: center;
  }

  .text {
    font-size: 9px;
  }
  .title {
    font-size: 24px;
  }

  .contactContainer {
    margin-top: 48px;
  }

  .contactTextColor {
    border-bottom: 2px solid #6a5acd;
    line-height: 20px;
    padding-bottom: 4px;
  }

}