
.background {
  background: url("../assets/images/GameScreen.jpeg");
  background-size: 100% 100%;
  /* position: absolute; */
  z-index: 10;
  top: 0;
  left: 0;
  /* background-repeat: no-repeat; */
  overflow: hidden !important;
  width: 100%;
  height:100vh;
}

.flickerOverlay {
  text-align: center;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}

.flickerOverlay:before {
  content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: -1;
    background-size: 100% 2px, 3px 100%;
    pointer-events: auto;
}

.flickerOverlay:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(18, 16, 16, 0.1);
  opacity: 0;
  z-index: -1;
  pointer-events: auto;
  animation: flickerAnimation 0.15s infinite;
  
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}

