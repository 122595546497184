.Logo {
    padding: 5px;
    height:100%;
    box-sizing:'border-box';
    border-radius:10px
}

.img{
    width:15vw;

    border-radius: 10px;

    /* transition: transform .2s;  */
    border: 2px solid #E6E2E2;
}
/* .img:hover {
    transform: scale(0.99)
} */

@media (max-width:1000px) {
    .img {
        width:30vw;
      
    }
  }


@media (max-width: 600px) {
    .img {
        height:20vh;
        width:40vw
       
    }
}
